body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.input.visa input, .input.mastercard input, .input.amex input, .input.discover input, .input.jcb input {
  background-repeat: no-repeat !important;
  background-position: 97% center  !important;
  background-size: 25px !important;
}

.input.visa input {
  background-image: url('/public/images/cards/visa.png') !important;
}

.input.mastercard input {
  background-image: url('/public/images/cards/mastercard.png') !important;
}

.input.amex input {
  background-image: url('/public/images/cards/amex.png') !important;
}

.input.discover input {
  background-image: url('/public/images/cards/discover.png') !important;
}

.input.jcb input {
  background-image: url('/public/images/cards/jcb.png') !important;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.video-container iframe, .video-container object, .video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-container iframe, .video-container object, .video-container embed {
  max-height: 390px;
}

@keyframes greenHighlight {
  0% {
    background: rgb(84, 140, 7);
  }
  100% {
    background: none;
  }
}

.highlight-green {
 animation: greenHighlight 1s;
}

.info-notice {
  color: #ccc;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.video-container iframe, .video-container object, .video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 390px;
  border: none;
}

.logo-img {
  max-width: 209px;
}

.amount > input::-webkit-outer-spin-button,
.amount > input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.amount > input[type=number] {
  -moz-appearance: textfield;
}

@-webkit-keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}
@keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}

.text-focus-in {
	-webkit-animation: text-focus-in 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: text-focus-in 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

.has-markdown p {
  margin: 0;
}





@media screen and (max-width: 1200px) {
  table.MuiTable-root.responsive thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table.MuiTable-root.responsive tr {
    display: block !important;
    border-top: 3px solid #ddd;
  }

  table.MuiTable-root.responsive tr td:first-child {
    font-weight: bold;
    text-align: left;
  }

  table.MuiTable-root.responsive td {
    display: block !important;
    text-align: right;
  }

  table.MuiTable-root.responsive td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
  }
}
